<template>
  <div class="h5-container h5-personal-container">
    <sub-head-navs title="个人中心"></sub-head-navs>
    <div class="personal-box">
      <div class="personal" :class="{'no-identity': !user.personId}">
        <div class="user-news">
          <h3 class="name" v-if="user.personId && user.name">{{ user.name }}</h3>
        </div>
        <div class="contact-box">
          <span class="phone" :class="{'no-identity': !user.personId}">{{ user.mobile }}</span>
          <span class="phone" :class="{'no-identity': !user.personId}">{{ user.email }}</span>
        </div>
        <template v-if="user.personId">
          <!-- 校园大使 -->
          <router-link v-if="user.externalFlag === 2" class="create-updated" :to="{name: 'userCreateUpdated'}">生成推荐码<i class="h5pp h5pp-icon-arrows-right" /></router-link>
          <!-- 内推 -->
          <div v-else-if="user.internalCode" class="recom-code">
            <span class="qr-code"><i class="h5pp h5pp-icon-qrcode"></i>内推码: <span class="qr-code-num">{{ user.internalCode }}</span></span>
            <a href="javascript:;" @click="copyUrl(user.internalCode)">复制</a>
          </div>
          <div v-if="user.externalFlag === 1" class="user-type out"><i class="h5pp h5pp-icon-user"></i>外部用户</div>
          <div v-else-if="user.externalFlag === 2" class="user-type ambassador"><i class="h5pp h5pp-icon-education1" />校园大使</div>
          <div v-else-if="user.externalFlag === 0" class="user-type company"><i class="h5pp h5pp-icon-company"></i>企业员工</div>
          <div class="bottom-share" :class="{'no-qr-code': !(user.internalCode || user.externalFlag === 2)}">
            <!-- 校园大使 -->
            <template v-if="user.externalFlag === 2">
              <router-link :to="{name: 'recordReferralCode'}"><i class="h5pp h5pp-icon-code2" />推荐码记录</router-link>
              <router-link :to="{name: 'recordNominate'}"><i class="h5pp h5pp-icon-file" />推荐记录</router-link>
            </template>
            <template v-else-if="+authStatus === 1">
              <span @click="onShareImg"><i class="h5pp h5pp-icon-image"></i>推广海报</span>
              <span @click="onShareUrl"><i class="h5pp h5pp-icon-share3"></i>分享链接</span>
            </template>
          </div>
        </template>
      </div>
    </div>
    <!-- 校园大使不展示 -->
    <div v-if="user.externalFlag !== 2" class="operate-btns">
      <a v-if="user.personId && user.externalFlag === 1" href="javascript:;" @click="$router.push({ name: 'identityEmployee', query: { pageFrom: 'userPersonal' }})">认证企业用户</a>
      <a v-if="+authStatus === 1" href="javascript:;" class="identity-btn" @click="onCancel">取消认证</a>
      <a v-else href="javascript:;" class="identity-btn"  @click="handleCertification">去认证<i class="h5pp h5pp-icon-arrows-right"></i></a>
    </div>
    <a href="javascript:;" class="sign-out-btn" @click="onSignOut">退出登录</a>
    <popup-preview-placard v-if="previewPlycardObj.isShow" :dialogData="previewPlycardObj" />
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Toast, Dialog } from 'vant'
  import { local } from '@/utils'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupPreviewPlacard from '@/components/h5/previewPlacard'
  import popupShareMenus from '@/components/h5/shareMenus'
 
  export default {
    components: {
      [Dialog.name]: Dialog,
      subHeadNavs,
      popupShareMenus,
      popupPreviewPlacard
    },
    data() {
      return {
        previewPlycardObj: { isShow: false }
      }
    },
    provide() {
      return {
        pageFrom: 'userPersonal'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo', 'user', 'authStatus']),
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      }
    },
    created() {
      if(this.user.personId) {
        this.queryUserInfo();
      }
    },
    methods: {
      copyUrl(url) {
        this.$copyText(url).then(() => {
            Toast.success({
            message: '已复制到粘贴板'
          });
        }, () => {
          Toast.success({
            message: '浏览器版本过低，请升级浏览器'
          });
        });
      },
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.user.personId
        }}).then(data => {
          if(data) {
            Object.assign(data, {personId: data.id});
            this.$store.commit('SET_USER_INFO', data);
          }
        });
      },
      onShareImg() {
        if(!this.user.mobile) {
          Dialog.confirm({
            title: '',
            message: '您还未登录，不会生成您的专属内推海报，确认继续分享吗？',
          }).then(() => {
            this.$utils.sendStat('0065', {
              webSiteId: this.uccnInfo.id,
              personId: this.user.personId || local.get('oldPersonId')
            });
            this.previewPlycardObj.isShow = true;
          });
        } else {
           this.$utils.sendStat('0065', {
            webSiteId: this.uccnInfo.id,
            personId: this.user.personId || local.get('oldPersonId')
          });
          this.previewPlycardObj.isShow = true;
        }
      },
      onShareUrl() {
        let url = `${window.location.origin}/job/list?websiteId=${this.uccnInfo.id}&personId=${this.user.personId || local.get('oldPersonId')}`;
        if(!this.user.mobile) {
          Dialog.confirm({
            title: '',
            message: '您还未登录，不会生成您的专属内推链接，确认继续分享吗？',
          }).then(() => {
            this.$utils.sendStat('0065', {
              webSiteId: this.uccnInfo.id,
              personId: this.user.personId || local.get('oldPersonId')
            });
            this.copyUrl(url);
          });
        } else {
          this.$utils.sendStat('0065', {
            webSiteId: this.uccnInfo.id,
            personId: this.user.personId || local.get('oldPersonId')
          });
          this.copyUrl(url);
        }
      },
      onCancel() {
        Dialog.confirm({
          title: '',
          message: '确认取消认证吗？取消认证后，将不再记录新的内推数据，您可以再次认证，我们将为您保留数据。',
        }).then(() => {
          this.$http.get('/api/internal/recom/person/cancel.json', { params: {
             websiteId: this.uccnInfo.id
          }}).then(data => {
            if(data) {
              this.$http.get('/api/internal/recom/person/flush.json', {params: {
                websiteId: this.uccnInfo.id
              }}).then(data => {
                this.$store.commit('SET_USER_INFO', data);
                location.reload();
              });
            }
          });
        });
      },
      onSignOut() {
        this.$http.get('/api/internal/recom/person/login/out.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.$store.commit('CLEAR_USER_INFO');
            if(local.get('rootPath')) {
              this.$router.push({
                name: 'Home',
                query: { id: this.uccnInfo.id },
                params: { pathMatch: local.get('rootPath') || '' }
              });
            } else {
              this.$router.go(-1);
            }
          }
        });
      },
      handleCertification() {
        if(!this.user.mobile && !this.user.email) {
          this. $router.push({ name: 'login', query: { routeName: 'userPersonal', loginType: 3 }})
        } else {
          this. $router.push({ name: 'identity', query: { pageFrom: 'userPersonal' }})
        }
      }
    }
  }
</script>
