<template>
  <van-overlay :show="dialogData.isShow" class="h5-preview-poster">
    <sub-poster :codeCnt="dialogData.codeCnt" />
    <div class="close-tip">
      <a href="javascript:;" @click="dialogData.isShow = false" class="h5pp h5pp-icon-close-empty" />
      <p>长按图片保存或转发</p>
    </div>
  </van-overlay>
</template>
<script>
  import './index.scss'
  import { Overlay } from 'vant'
  import subPoster from '@/components/pc/poster'

   export default {
    components: {
      [Overlay.name]: Overlay,
      subPoster
    },
    props: {
      dialogData: Object
    }
  }
</script>
