<template>
  <div v-if="activationFlag === 0" class="auth-ambassador">
    <sub-h5-auth-ambassador v-if="$utils.isPhone()" />
    <popup-login-process v-else :authAmbassadorObj="authAmbassadorObj" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import subH5AuthAmbassador from '@/views/submodule/h5/user/authAmbassador'
  import popupLoginProcess from '@/components/pc/dialog/loginProcess'

  export default {
    components: {
      subH5AuthAmbassador,
      popupLoginProcess
    },
    data() {
      return {
        activationFlag: -1, //0未激活；1已激活
        a: '',
        b: '',
        sign: '',
        authAmbassadorObj: { isShow: true }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.a = this.$route.query.a || '';
      this.b = this.$route.query.b || '';
      this.sign = this.$route.query.sign || '';
      this.queryData();
    },
    methods: {
      queryData() {
        this.$http.get('/api/school/ambassador/auth/info.json', {params: {
          websiteId: this.uccnInfo.id,
          a: this.a,
          b: this.b,
          sign: this.sign
        }}).then(data => {
          if(data) {
            this.activationFlag = data.activationFlag;
            if(data.activationFlag) {
              this.$store.commit('SET_USER_INFO', {
                name: data.name,
                mobile: data.mobile,
                personId: data.personId,
                companyId: data.companyId,
                externalFlag: data.externalFlag,
                type: data.type
              });
              this.$router.push({
                name: 'userPersonal'
              });
            }
          }
        });
      }
    }
  }
</script>
