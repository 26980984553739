<template>
  <div>
    <el-dialog
      title="生成推荐码"
      :visible.sync="dialogData.isShow"
      width="452px"
      modal
      :close-on-click-modal="false"
      custom-class="dialog-referral-code-cnt">
      <div class="referral-code-cnt">
        <span class="label">推荐码次数</span>
        <el-input-number size="small" v-model="codeCnt" :min="1" :max="maxNum" />
        <span class="surplus-cnt">剩余{{ surplus }}次</span>
      </div>
      <template slot="footer" class="dialog-footer">
        <el-button type="priamry" @click="onCreate">生成</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog, InputNumber, Button, Message } from 'element-ui'

  export default {
    components: {
      [Dialog.name]: Dialog,
      [InputNumber.name]: InputNumber,
      [Button.name]: Button
    },
    props: {
      dialogData: Object
    },
    data() {
      return {
        surplus: '',
        maxNum: Infinity,
        codeCnt: 1,
        upperNum: ''
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryData();
    },
    methods: {
      queryData() {
        this.$http.get('/api/school/ambassador/h5/recommend/number.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.recommendType = data.recommendType;
            if(data.recommendType === 1) {
              this.surplus = data.remainingNum || 0;
              this.maxNum = this.surplus;
            } else {
              this.surplus = '无限';
              this.maxNum = Infinity;
            }
            this.upperNum = data.upperNum || 0;
          }
        });
      },
      onCreate() {
        if(this.surplus !== '无限' && this.codeCnt > this.surplus) {
          Message.error('剩余次数已不足');
          return;
        }
        if(this.codeCnt > this.upperNum) {
          Message.error('推荐码次数超上限');
          return;
        }
        this.dialogData.isShow = false;
        this.$emit('success', this.codeCnt);
      }
    }  
  }
</script>