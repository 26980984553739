<template>
  <div class="auth-ambassador-box">
    <van-form class="user-form" ref="userForm" @submit="onSubmit">
      <template v-if="type === 1">
        <van-field v-model.trim="userForm.name" name="name" placeholder="请输入" :rules="[{ required: true, message: '请输入用户名' }]" />
        <van-field v-model="userForm.email" disabled />
        <van-field v-model="userForm.schoolName" disabled />
        <van-field v-model="userForm.mobile" disabled>
          <a href="javascript:;" class="field-link" @click="type = 2" slot="right-icon">修改手机号</a>
        </van-field>
      </template>
      <template v-else>
        <van-field type="number" maxlength="11" v-model.trim="userForm.mobile" name="mobile" placeholder="请输入" clearable :rules="[{ required: true, message: '请输入手机号' }, { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'onBlur'}]" />
        <van-field type="number" maxlength="6" v-model.trim="userForm.verifyCode" name="verifyCode" placeholder="请输入" clearable :rules="[{ required: true, message: '请输入验证码' }]">
          <a href="javascript:;" class="field-link" @click="sendVerifyCode" slot="right-icon">
            <template v-if="operateType === 2">
              <span class="second">{{ secondsCount }}s 后重新发送</span>
            </template>
            <template v-else>
              {{ operateType === 1 ? '发送验证码' : '重新发送' }}  
            </template>
          </a>
        </van-field>
      </template>
      <van-button class="btn-submit" type="primary" native-type="submit">提交</van-button>
    </van-form>
    <popup-share-menus />
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Form, Field, Button, Toast } from 'vant'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Button.name]: Button,
      popupShareMenus
    },
    data() {
      return {
        type: 1, // 1身份认证；2获取验证码
        a: '',
        b: '',
        sign: '',
        userForm: {
          name: '',
          email: '',
          schoolName: '',
          mobile: '',
          verifyCode: ''
        },
        operateType: 1,
        secondsCount: 60
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.a = this.$route.query.a || '';
      this.b = this.$route.query.b || '';
      this.sign = this.$route.query.sign || '';
      this.queryData();
    },
    methods: {
      queryData() {
        this.$http.get('/api/school/ambassador/auth/info.json', {params: {
          websiteId: this.uccnInfo.id,
          a: this.a,
          b: this.b,
          sign: this.sign
        }}).then(data => {
          if(data) {
            this.userForm = data;
          }
        });
      },
      cycleCallTime() {
        let timer = setTimeout(() => {
          if(this.secondsCount <= 0) {
            this.operateType = 3;
            this.secondsCount = 60;
            clearTimeout(timer);
            return;
          }
          this.secondsCount -= 1;
          this.cycleCallTime();
        }, 1e3);
      },
      sendVerifyCode() {
        if(this.operateType === 2) {
          return;
        }
        this.$refs.userForm.validateField('mobile').then(() => {
          this.$http.get('/api/auth/verify/code/send.json', {params: {
            mobile: this.userForm.mobile,
            sendType: 6
          }});
          this.operateType = 2;
          this.cycleCallTime();
        });
      },
      onSubmit() {
        if(this.type === 2) {
          this.$http.post('/api/school/ambassador/auth/update/mobile.json', {
            mobile: this.userForm.mobile,
            verifyCode: this.userForm.verifyCode,
            a: this.a,
            b: this.b,
            sign: this.sign
          }).then(data => {
            if(data.errCode) {
              Toast.fail(data.errMessage);
            } else {
              this.type = 1;
            }
          });
        } else {
          this.$http.post('/api/school/ambassador/auth/activation.json', {
            a: this.a,
            b: this.b,
            sign: this.sign,
            name: this.userForm.name
          }).then(data => {
            if(data) {
              this.$store.commit('SET_USER_INFO', {
                name: data.name,
                mobile: data.mobile,
                personId: data.personId,
                companyId: data.companyId,
                externalFlag: data.externalFlag,
                type: data.type
              });
              this.$router.push({
                name: 'userPersonal'
              });
            }
          });
        }
      }
    }
  }
</script>
