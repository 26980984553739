<template>
  <el-dialog 
    :visible.sync="dialogData.isShow" 
    :close-on-click-modal="false" 
    width="272px"
    class="dialog-pc-preview-poster">
    <sub-poster :codeCnt="dialogData.codeCnt"></sub-poster>
  </el-dialog>
</template>

<script>
  import './index.scss'
  import { Dialog } from 'element-ui'
  import subPoster from '@/components/pc/poster'
  
  export default {
    components: {
      [Dialog.name]: Dialog,
      subPoster
    },
    props: {
      dialogData: Object
    }
  }
</script>