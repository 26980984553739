<template>
  <div class="container main-page pc-personal-container">
    <sub-head-navs />
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ name: 'Home', query: { id: uccnInfo.id }, params: {pathMatch}}">招聘首页</el-breadcrumb-item>
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="personal-box" :class="{'identity-box': !personId}">
      <div class="box-left">
        <div class="register-box">
          <h3 v-if="user.name" class="name">{{ user.name }}</h3>
          <a v-if="user.personId && user.externalFlag === 1" href="javascript:;" @click="employeeObj.isShow = true">认证企业用户 <i class="h5pp h5pp-icon-arrows-right"></i></a>
        </div>
        <div class="phone" :class="{'no-identity': !personId}">{{ mobile }}</div>
        <div class="phone" :class="{'no-identity': !personId}">{{ email }}</div>
        <div v-if="personId" class="user-identity">
          <div v-if="user.externalFlag === 1" class="user-type"><i class="h5pp h5pp-icon-user" />外部用户</div>
          <div v-else-if="user.externalFlag === 2" class="user-type ambassador"><i class="h5pp h5pp-icon-education1" />校园大使</div>
          <div v-else-if="user.externalFlag === 0" class="user-type"><i class="h5pp h5pp-icon-company" />企业员工</div>
          <a v-if="+authStatus === 1" href="javascript:;" @click="onCancel">取消认证</a>
        </div>
        <div v-if="user.externalFlag === 2" class="referral-code">
          <i class="h5pp h5pp-icon-code2" />推荐码
          <a class="create-updated" href="javascript:;" @click="referralCodeCntObj.isShow = true">去生成<i class="h5pp h5pp-icon-arrows-right" /></a>
        </div>
        <div v-else-if="user.internalCode" class="user-internal-code"><span class="qr-code"><i class="h5pp h5pp-icon-code2"></i>内推码: <span class="qr-code-num">{{ user.internalCode }}</span></span><i class="h5pp h5pp-icon-copy" @click="copyUrl(user.internalCode)"></i></div>
      </div>
      <div class="bottom-share">
        <template v-if="user.externalFlag === 2">
          <router-link :to="{name: 'recordReferralCode'}"><i class="h5pp h5pp-icon-code2" />推荐码记录</router-link>
          <router-link :to="{name: 'recordNominate'}"><i class="h5pp h5pp-icon-file" />推荐记录</router-link>
        </template>
        <template v-else-if="+authStatus === 1">
          <a href="javascript:;" @click="onPreviewPlacard"><i class="h5pp h5pp-icon-image"></i>推广海报</a>
          <a href="javascript:;" @click="onShareUrl"><i class="h5pp h5pp-icon-share2"></i>分享链接</a>
        </template>
        <a v-else href="javascript:;" class="identity-btn" @click="handleCertification">去认证</a>
      </div>
    </div>
    <popup-login-process :loginObj="loginObj" :identityObj="identityObj" :employeeObj="employeeObj"></popup-login-process>
    <popup-preview-placard v-if="previewPlacardObj.isShow" :dialogData="previewPlacardObj"></popup-preview-placard>
    <popup-referral-code-cnt v-if="referralCodeCntObj.isShow" :dialogData="referralCodeCntObj" @success="createPoster" />
    <popup-preview-poster v-if="previewPosterObj.isShow" :dialogData="previewPosterObj" />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Breadcrumb, BreadcrumbItem, Message, MessageBox } from 'element-ui'
  import { local } from '@/utils'
  import subHeadNavs from '@/components/pc/headNavs'
  import popupLoginProcess from '@/components/pc/dialog/loginProcess'
  import popupPreviewPlacard from '@/components/pc/dialog/previewPlacard'
  import popupReferralCodeCnt from '@/components/pc/dialog/referralCodeCnt'
  import popupPreviewPoster from '@/components/pc/dialog/previewPoster'

  export default {
     components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [MessageBox.name]: MessageBox,
      subHeadNavs,
      popupLoginProcess,
      popupPreviewPlacard,
      popupReferralCodeCnt,
      popupPreviewPoster
    },
    data() {
      return {
        identityObj: { isShow: false },
        employeeObj: { isShow: false },
        loginObj: { isShow: false },
        previewPlacardObj: { isShow: false },
        referralCodeCntObj: { isShow: false },
        previewPosterObj: { isShow: false }
      }
    },
    computed: {
      ...mapGetters(['user', 'uccnInfo', 'authStatus', 'mobile', 'email']),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || '';
      },
      pathMatch() {
        return local.get('rootPath') || '';
      }
    },
    created() {
      console.log('user:', this.user);
      console.log('authStatus:', this.authStatus);
      if(this.personId) {
        this.queryUserInfo();
      }
    },
    methods: {
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId
        } }).then(data => {
          if(data) {
            Object.assign(data, { personId: data.id });
            this.$store.commit('SET_USER_INFO', data);
          }
        });
      },
      copyUrl(url) {
        this.$copyText(url).then(() => {
          Message.success('已复制到粘贴板');
        }, () => {
          Message.error('浏览器版本过低，请升级浏览器');
        });
      },
      onPreviewPlacard() {
        this.$utils.sendStat('0065', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId || local.get('oldPersonId')
        });
        this.previewPlacardObj.isShow = true
      },
      onCancel() {
        MessageBox.confirm('确认取消认证吗？取消认证后，将不再记录新的内推数据，您可以再次认证，我们将为您保留数据。','', {
        }).then(() => {
          this.$http.get('/api/internal/recom/person/cancel.json', { params: {
             websiteId: this.uccnInfo.id
          }}).then(data => {
            if(data) {
              this.$http.get('/api/internal/recom/person/flush.json', {params: {
                websiteId: this.uccnInfo.id
              }}).then(data => {
                console.log('flush data:', data);
                this.$store.commit('SET_USER_INFO', data);
                location.reload();
              });
            }
          });
        });
      },
      onShareUrl() {
        this.$utils.sendStat('0065', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId || local.get('oldPersonId')
        });
        let url = `${window.location.origin}/job/list?websiteId=${this.uccnInfo.id}&personId=${this.personId || local.get('oldPersonId')}`;
        this.$copyText(url).then(() => {
          Message.success('已复制到粘贴板');
        }, () => {
          Message.error('浏览器版本过低，请升级浏览器');
        });
      },
      createPoster(codeCnt) {
        Object.assign(this.previewPosterObj, {
          codeCnt,
          isShow: true
        });
      },
      handleCertification() { //认证
        if(!this.mobile) {
          Object.assign(this.loginObj, {
            type: 3,
            isShow: true
          })
        } else {
          this.identityObj.isShow = true;
        }
      }
    }
  }
</script>
