<template>
  <div class="h5-container h5-create-updated-box">
    <sub-head-navs title="个人中心"></sub-head-navs>
    <div class="referralcode-set-count">
      <div class="set-count">
        <span class="referralcode-surplus">推荐码次数<span>剩余{{ surplus }}次</span></span>
        <span class="stepper-box"><van-stepper v-model.number="codeCnt" integer input-width="40px" button-size="32px" :min="1" /></span>
      </div>
      <van-button type="primary" @click="onCreate">生成</van-button>
    </div>
    <popup-preview-poster v-if="previewPosterObj.isShow" :dialogData="previewPosterObj" />
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Row, Col, Stepper, Button, Toast } from 'vant'
  import subHeadNavs from '@/components/h5/headNavs'
  import popupPreviewPoster from '@/components/h5/previewPoster'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Row.name]: Row,
      [Col.name]: Col,
      [Stepper.name]: Stepper,
      [Button.name]: Button,
      subHeadNavs,
      popupPreviewPoster,
      popupShareMenus
    },
    data() {
      return {
        surplus: '',
        codeCnt: 1,
        previewPosterObj: { isShow: false },
        upperNum: ''
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryData();
    },
    methods: {
      queryData() {
        this.$http.get('/api/school/ambassador/h5/recommend/number.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.surplus = data.recommendType === 1 ? (data.remainingNum || 0) : '无限次';
            this.upperNum = data.upperNum || 0;
          }
        });
      },
      onCreate() {
        if(this.surplus !== '无限次' && this.codeCnt > this.surplus) {
          Toast.fail('剩余次数已不足');
          return;
        }
        if(this.codeCnt > this.upperNum) {
          Toast.fail('推荐码次数超上限');
          return;
        }
        this.previewPosterObj = {
          codeCnt: this.codeCnt,
          isShow: true
        }
      }
    }
  }
</script>