<template>
  <div class="personal-main">
    <van-loading v-if="isLoading" class="loading" color="#0055DC" />
    <template v-if="isLoaded">
      <sub-h5-personal v-if="$utils.isPhone()"></sub-h5-personal>
      <sub-pc-personal v-else></sub-pc-personal>
    </template>
  </div>
</template>

<script>
  import './index.scss'
  import { Loading } from 'vant'
  import subH5Personal from '@/views/submodule/h5/user/personal'
  import subPcPersonal from '@/views/submodule/pc/user/personal'

  export default {
    components: {
      [Loading.name]: Loading,
      subH5Personal,
      subPcPersonal
    },
    data() {
      return {
        isLoading: false,
        isLoaded: true
      }
    }
  }
</script>