<template>
  <el-dialog :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="pc-placard-modal">
    <sub-placard v-if="websiteInfo.id" :userName="userName" :configData="websiteInfo" />
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog } from 'element-ui'
  import { local } from '@/utils'
  import subPlacard from '@/components/pc/placard'

   export default {
    components: {
      [Dialog.name]: Dialog,
      subPlacard
    },
    props: {
      dialogData: Object
    },
    data() {
      return {
        websiteInfo: {},
        userName: '内推人'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user') || '{}')).personId || local.get('oldPersonId') || '';
      },
    },
    created() {
      if(this.personId) {
        this.queryUserInfo();
      } else {
        this.queryWebsite();
      }
    },
    methods: {
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId
        } }).then(data => {
          if(data) {
            this.userName = data.name;
          }
          this.queryWebsite();
        });
      },
      queryWebsite() {
        this.$http.get('/api/internal/recom/websit/detail.json', {params: {
          websiteId: this.uccnInfo.id
        } }).then(data => {
          if(data) {
            this.websiteInfo = data;
            let reg = new RegExp(/<strong class="email-tag" data-id="1"( style="")?>.*?<\/strong>/, 'g');
            if(this.userName) {
              this.websiteInfo.customContent = this.websiteInfo.customContent.replace(reg, this.userName);
            } else {
              this.websiteInfo.customContent = this.websiteInfo.customContent.replace(reg, '<strong class="email-tag" data-id="1"><\/strong>');
            }
          }
        });
      }
    }
  }
</script>
