<template>
  <sub-h5-create-updated v-if="$utils.isPhone()" />
</template>

<script>
  import subH5CreateUpdated from '@/views/submodule/h5/user/createUpdated'

  export default {
    components: {
      subH5CreateUpdated
    }
  }
</script>
