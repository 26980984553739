<template>
  <div class="share-ambassador-poster">
    <div ref="poster" class="ambassador-poster">
      <img src="@/assets/images/ambassador/img_title.png" class="img-title" />
      <h4>我是校园大使 {{ user.name || '' }}</h4>
      <p class="join-company">{{ uccnInfo ? uccnInfo.companyName : '' }}期待您的加入</p>
      <dl class="referral-code">
        <dt>{{ code }}</dt>
        <dd>投递时&nbsp;输入推荐码</dd>
      </dl>
      <div class="qrcode-box">
        <div class="img-qrcode">
          <div ref="qrcodeImg"></div>
        </div>
        <p class="remind">使用微信扫一扫<br>或长按识别</p>
      </div>
      <img src="@/assets/images/ambassador/img_laborer.png" class="img-laborer" />
    </div>
    <div ref="posterImg" class="img-share">
      <img v-if="dataURL" :src="dataURL">
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import { Message } from 'element-ui'
  import { mapGetters } from 'vuex'
  import QRCode from 'qrcodejs2'
  import html2canvas from 'html2canvas'

  export default {
    props: {
      codeCnt: [Number, String]
    },
    data() {
      return {
        code: '',
        websiteUrl: '',
        dataURL: '',
        loading: ''
      }
    },
    computed: {
      ...mapGetters(['user', 'uccnInfo'])
    },
    async mounted() {
      let hasInfo = await this.queryData();
      if(!hasInfo) {
        Message.error('未获取到分享信息');
        return;
      }
      this.loading = this.$loading({
        target: this.$refs.placardImg,
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      });
      this.createQrCode(this.websiteUrl);
      this.createHtmlToImg();
    },
    methods: {
      queryData() {
        return new Promise((resolve, reject) => {
          this.$http.post('/api/school/ambassador/h5/generate/recommend/code.json', {
            websiteId: this.uccnInfo.id,
            number: this.codeCnt
          }).then(data => {
            if(data) {
              this.code = data.code;
              this.websiteUrl = data.websiteUrl;
              resolve(true);
            } else {
              resolve(false);
            }
          }).catch(err => {
            resolve(false);
          });
        });
      },
      createQrCode(url) {
        let $qrCodeDom = this.$refs.qrcodeImg;
        $qrCodeDom.innerHTML = '';
        let widthH = this.$utils.isPhone() ? 80 : 62;
        new QRCode($qrCodeDom, {
          text: `${url}`,
          width: widthH,
          height: widthH
        });
      },
      createHtmlToImg() {
        const dom = this.$refs.poster;
        html2canvas(dom, {
          useCORS: false, //（图片跨域相关）
          allowTaint: false, //允许跨域（图片跨域相关）
          background: null
        }).then(canvas => {
          this.dataURL = canvas.toDataURL('image/png');
          this.loading.close();
        })
      }
    }
  }
</script>